/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { MDXRenderer } from 'gatsby-plugin-mdx'

export const pageQuery = graphql`
  query ContactQuery($id: String!){
    graphCmsPage(id: { eq: $id }) {
      id
      title
      content {
        markdownNode {
          childMdx {
            body
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Contact = ({data}) => {
  const { graphCmsPage, site } = data // data.markdownRemark holds your post data
  const { title, content } = graphCmsPage

  return  (
    <Layout className="contact-page" sx={contactStyles.contactPage}>
      <SEO 
        title={title}
        description={title + " " + site.siteMetadata.title}
      />
      <div className="wrapper">
        <h1>{title}</h1>
        <article>
          <MDXRenderer>{content.markdownNode.childMdx.body}</MDXRenderer>
        </article>
      </div>

    </Layout>
  )
}

export default Contact

const contactStyles = {
  contactPage:{
    "input":{
      border:"6px solid",
      borderColor: "inputBorder",
      bg: "inputBackground"
    },
    "textarea": {
      border:"6px solid",
      borderColor: "inputBorder",
      bg: "inputBackground"
    }
  }
}